// Packages
import React from "react";

// Types
import type { Location } from "~types/Gatsby";

// Components
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H2";
import Enquiery from "~components/layouts/Enquiery";
import StandardForm from "~components/forms/StandardForm";

export default function Page({ location }: { location: Location }) {
	return (
		<Layout
			location={location}
			title="Juristen Speed Reading"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			hideHeader
		>
			<Enquiery>
				<H1 display className="mb-5">
					Juristen Speed Reading
				</H1>
				<StandardForm formName="Juristen Speed Reading" />
			</Enquiery>
		</Layout>
	);
}
